<template>
    <div class="container">

        <!-- Page Title -->
        <div class="page-title">
            <h1><button class="btn btn-danger btn-sm ml-auto" @click="$bvModal.show('complete-return-modal')">Complete return</button></h1>
            <h4>1 of 48 kits picked <i class="fa fa-redo fa-xs ml-2"></i></h4> <!-- TODO: Click the icon to refresh the table data -- do we need it on this page? -->
        </div>

        <!-- Repair kit details -->
        <div class="card card-body card-body-sm">
            <div class="media align-items-center">
                <div class="media-body">
                    <h5 class="mb-0">Repair kit #1234</h5>
                </div>
                <div class="media-right">
                    <router-link :to="{ name: 'repairKitReturns'}" class="btn btn-danger btn-sm">Change</router-link>
                </div>
            </div>
        </div>

        <!-- Title -->
        <div class="title">
            <h2>Items to return</h2>
            <h5 class="text-subtitle">2 of 5 scanned</h5>
        </div>

        <!-- Scan shipment form -->
        <div class="row mb-5">
            <div class="col-12">
                <label>Enter the item</label>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" class="form-control" />
                    <small class="form-text text-muted">Type the item number or use the barcode scanner</small>
                </div>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-block d-md-inline-block mb-3">Add item</button>
            </div>
            <div class="col-12">
                <button class="btn btn-info btn-block btn-sm d-md-inline-block mb-3">Open barcode scanner</button>
            </div>
        </div>

        <!-- Items List -->
        <b-tabs content-class="mt-3">
            <!-- TODO: Using tabs like this might not be the best way to filter these results - I can replicate it in HTML if required -->
            <b-tab title="All kits" active>
                <div class="card">
                    <ul class="checklist my-0">
                        <li class="checklist-item no-hover">Part name</li>
                        <li class="checklist-item no-hover">Part name</li>
                        <li class="checklist-item no-hover complete">Part name</li>
                        <li class="checklist-item no-hover complete">Part name</li>
                        <li class="checklist-item no-hover">Part name</li>
                    </ul>
                </div>
            </b-tab>
            <b-tab title="To pick">
                <div class="card">
                    <ul class="checklist my-0">
                        <li class="checklist-item no-hover">Part name</li>
                        <li class="checklist-item no-hover">Part name</li>
                        <li class="checklist-item no-hover">Part name</li>
                    </ul>
                </div>
            </b-tab>
            <b-tab title="Picked">
                <div class="card">
                    <ul class="checklist my-0">
                        <li class="checklist-item no-hover complete">Part name</li>
                        <li class="checklist-item no-hover complete">Part name</li>
                    </ul>
                </div>
            </b-tab>
        </b-tabs>

        <!-- Return complete modal -->
        <return-complete-modal></return-complete-modal>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                returnShipmentScanned: false
            }
        },

        methods: {
            /** Add a repair kit */
            addRepairKit() {
                this.returnShipmentScanned = true;
            },

            /** Clear a repair kit */
            clearRepairKit() {
                this.returnShipmentScanned = false;
            }
        }
    }
</script>

<style scoped>

</style>